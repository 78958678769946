import { useContext, useMemo, useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { FormCheckbox, SwitchThemeContext, TagProps } from '@tryhackme/thm-ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { ThemeColorMode } from 'src/common/enums';
import { CompanyData } from 'src/common/interfaces';
import { useGetCompanyQuery } from 'src/common/slices';
import { StyledDropdownCheckboxItem, StyledDropdownLabel, StyledDropdownMenuContent, StyledDropdownMenuItemGroup, StyledDropdownMenuItemGroupAllUsers, StyledDropdownMenuSeparator, StyledDropdownTrigger, StyledSearchIcon, StyledTag, StyledTagWrapper, StyledTextField, StyledTextFieldWrapper, StyledTriggerButton, StyledText } from './users-groups-dropdown.styles';
export type SelectedProp = {
  groups: string[];
  users: string[];
  all: boolean;
};
type UsersGroupsDropdownBaseProps = {
  selected: SelectedProp;
  updateSelected: (state: SelectedProp) => void;
  disabled?: boolean;
  isMitre?: boolean;
};
export type UsersGroupsDropdownProps = UsersGroupsDropdownBaseProps & ({
  companyId: string;
  data?: never;
} | {
  companyId?: never;
  data?: CompanyData;
});
export const UsersGroupsDropdown = ({
  updateSelected,
  selected,
  companyId = '',
  data,
  disabled,
  isMitre = false
}: UsersGroupsDropdownProps) => {
  const queryResponse = useGetCompanyQuery(companyId, {
    skip: !companyId
  });
  return <Component selected={selected} updateSelected={updateSelected} data={companyId ? queryResponse.data : data} disabled={disabled} isMitre={isMitre} data-sentry-element="Component" data-sentry-component="UsersGroupsDropdown" data-sentry-source-file="users-groups-dropdown.tsx" />;
};
const handleWheel = (event: React.WheelEvent) => {
  const scrollContainer = event.currentTarget;
  scrollContainer.scrollTop += event.deltaY;
  event.stopPropagation();
};
const Component = ({
  data,
  selected,
  updateSelected,
  disabled,
  isMitre
}: UsersGroupsDropdownBaseProps & {
  data?: CompanyData;
}) => {
  const {
    theme
  } = useContext(SwitchThemeContext);
  const isDarkMode = theme === ThemeColorMode.DARK;
  const tagProps = ({
    type: 'dismissable',
    size: 'small',
    color: isDarkMode ? 'secondary' : 'grey',
    variation: 200,
    textColor: 'lighter'
  } as TagProps);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const seatedUsers = useMemo(() => data?.users.filter(seat => seat.userId) ?? [], [data?.users]);
  const groups = data?.groups.filter(group => group.users.length) ?? [];
  const updateGroups = (state: boolean, groupId: string) => {
    if (state) {
      updateSelected({
        ...selected,
        all: false,
        groups: [...new Set([...selected.groups, groupId])]
      });
    } else {
      updateSelected({
        ...selected,
        all: false,
        groups: selected.groups.filter(id => id !== groupId)
      });
    }
  };
  const updateUsers = (state: boolean, userId: string) => {
    if (state) {
      updateSelected({
        ...selected,
        all: false,
        users: [...new Set([...selected.users, userId])]
      });
    } else {
      updateSelected({
        ...selected,
        all: false,
        users: selected.users.filter(id => id !== userId)
      });
    }
  };
  const updateAll = (state: boolean) => {
    updateSelected({
      users: [],
      groups: [],
      all: state
    });
    setIsOpen(false);
  };
  const showTag = selected.groups.length !== 0 || selected.users.length !== 0;
  const isEmpty = selected.all === false && !showTag;
  const filteredGroups = groups.filter(group => group.title.toLowerCase().includes(searchTerm.trim().toLowerCase()));
  const filteredUsers = useMemo(() => seatedUsers.filter(user => user.email.toLowerCase().includes(searchTerm.trim().toLowerCase())), [searchTerm, seatedUsers]);
  const selectedGroups = groups.filter(group => selected.groups.includes(group._id));
  const selectedUsers = useMemo(() => seatedUsers.filter(user => selected.users.includes(user.userId)), [seatedUsers, selected.users]);
  return <DropdownMenu.Root open={isOpen} data-sentry-element="unknown" data-sentry-component="Component" data-sentry-source-file="users-groups-dropdown.tsx">
      <StyledDropdownTrigger asChild disabled={disabled} data-sentry-element="StyledDropdownTrigger" data-sentry-source-file="users-groups-dropdown.tsx">
        <StyledTriggerButton color="secondary" variant="outlined" $isEmpty={isEmpty} onClick={() => setIsOpen(p => !p)} $isOpen={isOpen} $isDarkMode={isDarkMode} aria-label="Click here to select groups and users" data-sentry-element="StyledTriggerButton" data-sentry-source-file="users-groups-dropdown.tsx">
          <StyledText data-sentry-element="StyledText" data-sentry-source-file="users-groups-dropdown.tsx">
            {isEmpty && 'Select user(s) or group(s)'}

            {selected.all && !isMitre && 'All users and groups'}

            {(selectedGroups.length === 1 && selectedGroups[0]?.title) ?? '1 Group'}

            {selectedGroups.length > 1 && `${selectedGroups.length} Groups`}

            {selectedGroups.length >= 1 && selectedUsers.length >= 1 && ' and '}

            {(selectedUsers.length === 1 && selectedUsers[0]?.email) ?? '1 Group'}

            {selectedUsers.length > 1 && `${selectedUsers.length} Users`}
          </StyledText>

          <FontAwesomeIcon icon={faChevronDown} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="users-groups-dropdown.tsx" />
        </StyledTriggerButton>
      </StyledDropdownTrigger>

      <DropdownMenu.Portal data-sentry-element="unknown" data-sentry-source-file="users-groups-dropdown.tsx">
        <StyledDropdownMenuContent $searchHeight={!!searchTerm} sideOffset={4} side="bottom" onPointerDownOutside={() => setIsOpen(false)} onEscapeKeyDown={() => setIsOpen(false)} onWheel={handleWheel} data-sentry-element="StyledDropdownMenuContent" data-sentry-source-file="users-groups-dropdown.tsx">
          <StyledTextFieldWrapper data-sentry-element="StyledTextFieldWrapper" data-sentry-source-file="users-groups-dropdown.tsx">
            <StyledTextField autoFocus placeholder="Search" value={searchTerm} onChange={e => setSearchTerm(e.target.value)} leftAdornment={<StyledSearchIcon $isDarkMode={isDarkMode} icon={faMagnifyingGlass} />} onKeyDown={e => {
            e.stopPropagation();
          }} variant="input" data-sentry-element="StyledTextField" data-sentry-source-file="users-groups-dropdown.tsx" />
          </StyledTextFieldWrapper>
          {showTag && <>
              <StyledDropdownMenuSeparator />
              <StyledTagWrapper>
                {selected.all && !isMitre && <StyledTag {...tagProps} key="all-users-tag" handleDismissTag={() => updateAll(false)}>
                    All users and groups
                  </StyledTag>}

                {selectedGroups.map(group => <StyledTag {...tagProps} key={`group-tag-${group._id}`} handleDismissTag={() => updateGroups(false, group._id)}>
                    {group.title ?? group._id}
                  </StyledTag>)}

                {selectedUsers.map(user => <StyledTag {...tagProps} key={`user-tag-${user.userId}`} handleDismissTag={() => updateUsers(false, user.userId)}>
                    {user.email ?? user?.userId}
                  </StyledTag>)}
              </StyledTagWrapper>
            </>}

          <StyledDropdownMenuSeparator data-sentry-element="StyledDropdownMenuSeparator" data-sentry-source-file="users-groups-dropdown.tsx" />

          {!isMitre && <StyledDropdownMenuItemGroupAllUsers>
              <StyledDropdownCheckboxItem $isDarkMode={isDarkMode} checked={selected.all} onCheckedChange={state => updateAll(state)}>
                <FormCheckbox checked={selected.all} />
                <p>All users and groups</p>
              </StyledDropdownCheckboxItem>
            </StyledDropdownMenuItemGroupAllUsers>}

          {!!filteredUsers.length && <StyledDropdownLabel>Users</StyledDropdownLabel>}

          <StyledDropdownMenuItemGroup data-sentry-element="StyledDropdownMenuItemGroup" data-sentry-source-file="users-groups-dropdown.tsx">
            {filteredUsers.map(({
            email,
            userId
          }) => <StyledDropdownCheckboxItem $isDarkMode={isDarkMode} checked={selected.users.includes(userId)} key={userId} onCheckedChange={checked => updateUsers(checked, userId)}>
                <FormCheckbox checked={selected.users.includes(userId)} />

                <p>{email}</p>
              </StyledDropdownCheckboxItem>)}
          </StyledDropdownMenuItemGroup>

          {!!filteredGroups.length && <StyledDropdownLabel>Groups</StyledDropdownLabel>}

          <StyledDropdownMenuItemGroup data-sentry-element="StyledDropdownMenuItemGroup" data-sentry-source-file="users-groups-dropdown.tsx">
            {filteredGroups.map(({
            title,
            _id
          }) => <StyledDropdownCheckboxItem $isDarkMode={isDarkMode} checked={selected.groups.includes(_id)} key={_id} onCheckedChange={checked => updateGroups(checked, _id)}>
                <FormCheckbox checked={selected.groups.includes(_id)} />

                <p>{title}</p>
              </StyledDropdownCheckboxItem>)}
          </StyledDropdownMenuItemGroup>
        </StyledDropdownMenuContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>;
};